import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/event_locations"

export default {
  async getAll(params) {
    let response = await Repository.get(resource, {
      params: { ...params, ...{ region_id: store.state.auth.chosenRegion } }
    })
    return response.data
  },
  async get(locationID) {
    let response = await Repository.get(`${resource}/${locationID}`)
    return response.data.event_location
  },
  async create(locationData) {
    let response = await Repository.post(`${resource}`, {
      event_location: locationData
    })
    return response.data
  },
  async update(locationID, locationData) {
    let response = await Repository.patch(`${resource}/${locationID}`, {
      event_location: locationData
    })
    return response.data
  }
  // async destroy(locationID) {
  //   let response = await Repository.delete(`${resource}/${locationID}`)
  //   return response.data
  // }
}
