<template>
  <base-form
    v-slot="slotProps"
    :model="location"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    model-name="Veranstaltungsort"
  >
    <el-form-item
      v-if="canManageEvents && !persisted"
      label="Veranstalter"
      prop="support_organization_id"
    >
      <el-select
        v-model="slotProps.model.support_organization_id"
        v-loading="loadingOrganizations"
        filterable
        placeholder="Bitte auswählen"
      >
        <el-option
          v-for="item in organizations"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Straße und Hausnummer" prop="address">
      <el-input v-model="slotProps.model.address"></el-input>
    </el-form-item>

    <el-form-item label="Postleitzahl" prop="zipcode">
      <el-input v-model="slotProps.model.zipcode"></el-input>
    </el-form-item>

    <el-form-item label="Ort" prop="city">
      <el-input v-model="slotProps.model.city"></el-input>
    </el-form-item>
  </base-form>
</template>

<script>
import { mapGetters } from "vuex"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import BaseForm from "@/components/forms/BaseForm"
import SupportOrganizationsRepository from "@/repositories/support_organizations_repository.js"

export default {
  components: {
    BaseForm
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    location: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingOrganizations: true,
      organizations: []
    }
  },
  computed: {
    persisted() {
      return this.location.hasOwnProperty("id")
    },
    rules() {
      return {
        support_organization_id: [
          {
            required: this.canManageEvents && !this.persisted,
            message: "Bitte Veranstalter auswählen",
            trigger: "change"
          }
        ],
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Bitte Adresse angeben", trigger: "blur" }
        ],
        zipcode: [
          {
            required: true,
            message: "Bitte gültige PLZ angeben",
            trigger: "blur"
          }
        ],
        city: [
          { required: true, message: "Bitte Stadt angeben", trigger: "blur" }
        ]
      }
    },
    ...mapGetters("auth", ["canManageEvents"])
  },
  async created() {
    if (this.canManageEvents) {
      try {
        let data = await SupportOrganizationsRepository.getAll({
          show_all: true
        })
        this.organizations = data.support_organizations
        this.loadingOrganizations = false
      } catch (error) {
        this.loadingOrganizations = false
        this.handleApiError(error)
      }
    }
  },
  methods: {}
}
</script>
